import React from 'react';
import './footer.scss';
import footerImage from '../../shared/assets/img/footerimg.svg';
import { Button } from '../button/Button';
import { Link } from '../link/Link';
import { useLocation } from 'react-router-dom';


export const Footer: React.FC = () => {
    const location = useLocation();
    const pathname = location.pathname;

    return <footer className='footer-wrapper'>
           {!pathname.includes('/work-with-us') && <div className='footer'>
                <img className='footer__image' src={footerImage} alt='Virtual Reality employee' />
                <div className='footer__content-wrapper'>
                    <h2 className='footer__content-title'>
                        Build your experience with us
                    </h2>
                    <div className='footer__content-text'>
                        <span className='content-text__list-info'>We can provide you with:</span>
                        <ul>
                            <li>Flexible and tailored services,</li>
                            <li>Extensive understanding of humanitarier contexts</li>
                            <li>Collaborative and agile approach</li>
                            <li>A rich repository of content</li>
                            <li>A proven track record.</li>
                        </ul>
                    </div>
                    <Button classes={{root: 'button button--primary', sizeLarge: 'button--large'}} size='large' text='Work with us' disableRipple href="/work-with-us"></Button>
                </div>
            </div>}
            <nav className='footer__sub-nav'>
                <div className='sub-nav__info'>
                    <span className='footer__copyright'>Copyright ICRC 2024</span>
                    <Link classes={{root: 'footer__link'}}href="https://www.icrc.org" target="_blank" rel="noreferrer">icrc.org</Link>
                </div>
                <div  className='sub-nav__links'>
                    <Link classes={{root: 'footer__link mr-16'}}href="/" target="_blank" rel="noreferrer">Legal mentions</Link>
                    <Link classes={{root: 'footer__link mr-16'}}href="/" target="_blank" rel="noreferrer">Privacy policy</Link>
                    <Link classes={{root: 'footer__link'}}href="/" target="_blank" rel="noreferrer">Cookies</Link>
                </div>
            </nav>
        </footer>
}